<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>채팅 상담이력 관리</h1>
      <div class="is-right">
      </div>
    </div>
    <div class="jh-search-form">
      <table>
        <colgroup>
          <col width="55px">
          <col width="250px">
          <col width="95px">
          <col width="120px">
          <col width="80px">
          <col width="120px">
          <col width="80px">
          <col width="120px">
          <col width="80px">
          <col width="120px">
          <col width="120px">
          <col width="120px">
          <col width="70px">
          <col width="120px">
          <col>
        </colgroup>
        <tr>
          <th><label>조회기간</label></th>
          <td>                        
            <div class="jh-cols">                        
              <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dates[0]" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                </template>
                <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
              </v-menu>
              <span class="jh-unit">~</span>                       
              <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dates[1]" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                </template>
                <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
              </v-menu>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>문의유형</label></th>
          <td>
            <div class="jh-cols">
              <v-select class="jh-form" v-model="select_inqry_code_1" :items="inqry_code_1" item-value="CD" item-text="CD_NM" @change="changeInqryKey('001' , select_inqry_code_1, '1')"></v-select>
              <v-select class="jh-form" v-model="select_inqry_code_2" :items="inqry_code_2" item-value="CD" item-text="CD_NM" @change="changeInqryKey('001' , select_inqry_code_2, '2')"></v-select>
            </div>
          </td>
          <td colspan="2">
            <v-select class="jh-form" v-model="select_inqry_code_3" :items="inqry_code_3" item-value="CD" item-text="CD_NM"></v-select>
          </td>
          <th><label>인입채널</label></th>
          <td>
            <v-select class="jh-form" v-model="selectInCode" :items="srhCallTypCd"  item-value="CD" item-text="CD_NM" placeholder="선택하세요"></v-select>
          </td>
          <th><label>입력번호</label></th>
          <td>
            <input type="text" class="jh-form" @keyup.enter="search" v-model="srhUserPhNum">
          </td>
          <th><label>상담사</label></th>
            <td><v-select class="jh-form" v-model="ATTR_A" :items="DEPT_COMBO" item-value="CD" item-text="CD_NM" @change="changeDept(ATTR_A)"></v-select></td>
            <td><v-select class="jh-form" v-model="ATTR_B" :items="TEAM_COMBO" item-value="CD" item-text="CD_NM" @change="changeUser(ATTR_A, ATTR_B)"></v-select></td>
            <td><v-select class="jh-form" v-model="CNSL_ID" :items="CNSL_COMBO" item-value="USER_ID" item-text="USER_NM"></v-select></td>
          <!--
          <td><v-btn class="jh-btn is-fill has-icon-only" @click="showDialog('P101801')"><i class="jh-icon-search-sm is-white"></i></v-btn></td>
          <td><v-select class="jh-form" v-model="ATTR_A" :items="DEPT_COMBO" item-value="CD" item-text="CD_NM" @change="changeDept(ATTR_A)"></v-select></td>
          <td><v-select class="jh-form" v-model="ATTR_B" :items="TEAM_COMBO" item-value="CD" item-text="CD_NM" @change="changeUser(ATTR_A, ATTR_B)"></v-select></td>
          <td><v-select class="jh-form" v-model="cnslInfo.CNSL_USID" item-value="USER_ID" item-text="USER_NM" :items="CNSL_COMBO"></v-select></td>
          -->
          <!--
          <th><label>ISSUE</label></th>
          <td>
            <input type="text" class="jh-form">
          </td>
          -->
          <th><label>상담결과</label></th>
          <td><v-select class="jh-form" v-model="cnslrsltcd" :items="srhCnslRsltCd" item-value="CD" item-text="CD_NM"></v-select></td>
        </tr>
        <tr>                    
          <th><label>상담유형</label></th>
          <td>
            <div class="jh-cols">
                <v-select class="jh-form" v-model="cnsltypcd" :items="srhCnslTypCd" item-text="CD_NM" item-value="CD" placeholder="선택하세요"
                @change="changeCouselTyeKey('001', cnsltypcd, '1')"></v-select>
                <v-select class="jh-form" v-model="cnsltypcd2" :items="srhCnslTypCd2" item-text="CD_NM" item-value="CD" placeholder="선택하세요"
                @change="changeCouselTyeKey('001', cnsltypcd2, '2')"></v-select>
            </div>
          </td>
          <td colspan="2">
            <v-select class="jh-form" v-model="cnsltypcd3" :items="srhCnslTypCd3" item-text="CD_NM" item-value="CD" placeholder="선택하세요"
            @change="changeCouselTyeKey('001', cnsltypcd3, '3')"></v-select>
          </td>
          <th><label>고객등급</label></th>
          <td><v-select class="jh-form" v-model="srhCusLevel" :items="CLIENTRATING" item-text="CD_NM" item-value="CD_NM" placeholder="선택하세요"></v-select></td>
          <th><label>고객번호</label></th>
          <td>
            <input type="text" class="jh-form" @keyup.enter="search" v-model="srhCustId">
          </td>
          <th><label>고객명</label></th>
          <td>
            <input type="text" class="jh-form" @keyup.enter="search" v-model="srhCustomerNm">
          </td>
          <th><label>고객ID</label></th>
          <td>
              <input type="text" class="jh-form" @keyup.enter="search" v-model="srhTalkUserKey">
          </td>
          <th><label>키워드</label></th>
          <td>
            <input v-model="talkQst" type="text" @keyup.enter="search" class="jh-form">
          </td>
          <td class="has-search"><v-btn class="jh-btn is-search" @click="search">조회</v-btn></td>
        </tr>
      </table>
    </div>

    <div class="jh-form-wrap">
      <div class="jh-ui-header">
          <h2>상담이력 목록</h2>
          <div class="is-right">
              <v-btn class="jh-btn is-light" @click="downloadExcel('cnslHistTable', '채팅 상담이력 관리', '상담이력 목록')">엑셀다운로드</v-btn>
          </div>
      </div>

      <!--
      <v-data-table
        height="290px"
        dense
        :headers="gridDataHeaders"
        :items="gridDataText"
        :items-per-page="50"
        item-key="index"
        :page.sync="page"
        hide-default-footer
        class="grid-default"        
        @page-count="pageCount = $event"
        @click:row="detail"        
        no-data-text="조회된 결과가 없습니다."
        fixed-header
        :item-class="isActiveRow"
      >
      <template v-slot:item.TALK_DIST_DT="{ item }">
            <div>{{ yyyyMMddHHMISS(item.TALK_DIST_DT) }}</div>
        </template>
        <template v-slot:item.TALK_START_DT="{ item }">
            <div>{{ yyyyMMddHHMISS(item.TALK_START_DT) }}</div>
        </template>
        <template v-slot:item.TALK_POST_DT="{ item }">
            <div>{{ yyyyMMddHHMISS(item.TALK_POST_DT) }}</div>
        </template>
        <template v-slot:item.TALK_END_DT="{ item }">
            <div>{{ yyyyMMddHHMISS(item.TALK_END_DT) }}</div>
        </template>
        <template v-slot:item.CONTENT_VIEW>
          <button type="button" class="jh-btn is-icon" title="대화내용보기" @click.stop="openP101102"><i class="jh-icon-comment is-blue"></i></button>
        </template>
      </v-data-table>
      -->

      <data-tables
        ref="dataTable"
        id="cnslHistTable"
        dataTableClass="has-control"
        :data-table-options="dataTableOptions"
        :paginationOptions="paginationOptions"
        @click:row="onClickRow"
      >
        <template v-slot:item.INT_ACC_NO="{ item }">
            <div>{{ mixin_accNoFormat(item.INT_ACC_NO) }}</div>
        </template>
        <template v-slot:item.TALK_DIST_DT="{ item }">
            <div>{{ yyyyMMddHHMISS(item.TALK_DIST_DT) }}</div>
        </template>
        <template v-slot:item.TALK_START_DT="{ item }">
            <div>{{ HHMISS(item.TALK_START_DT) }}</div>
        </template>
        <template v-slot:item.TALK_POST_DT="{ item }">
            <div>{{ HHMISS(item.TALK_POST_DT) }}</div>
        </template>
        <template v-slot:item.TALK_END_DT="{ item }">
            <div>{{ yyyyMMddHHMISS(item.TALK_END_DT) }}</div>
        </template>
        <template v-slot:item.PERS_INF_AGG_DTTM="{ item }">
            <div>{{ (item.PERS_INF_AGG_DTTM.length > 20)? item.PERS_INF_AGG_DTTM.substring(11,19) : item.PERS_INF_AGG_DTTM }}</div>
        </template>
        <template v-slot:item.USER_PH_NUM="{ item }">
            <div>{{ phonNumber(item.USER_PH_NUM) }}</div>
        </template>
        <template v-slot:item.CONTENT_VIEW={item}>
          <button type="button" class="jh-btn is-icon" title="대화내용보기" @click.stop="openP101102(item)"><i class="jh-icon-comment is-blue"></i></button>
        </template>
      </data-tables>

    </div>
    <div class="jh-form-wrap">
      <div class="jh-ui-header">
        <h2>상담이력 상세</h2>
        <div class="is-right">
          <v-btn class="jh-btn is-main" v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" @click="modify">저장</v-btn>
        </div>
      </div>
      <table class="jh-tbl-detail">
        <colgroup>
          <col width="90px">
          <col width="140px">
          <col width="90px">
          <col width="200px">
          <col width="90px">
          <col width="120px">
          <col width="90px">
          <col width="300px">
          <col width="90px">
          <col>
        </colgroup>
        <tbody>
          <tr>
            <th><label>인입채널</label></th>
            <td><v-text-field class="jh-form is-trns" v-model="contactDetail.IN_CODE_NM" :disabled="true" id=""></v-text-field></td>
            <th rowspan="3"><label class="is-required">상담유형</label></th>
            <td>
              <!--상담유형 대분류-->
              <v-select class="jh-form" v-model="contactDetail.CNSL_TYP_CD" :items="mdfyCnslTypCd" item-text="CD_NM" item-value="CD" placeholder="선택하세요" @change="changeMdfyCouselTyeKey('001', contactDetail.CNSL_TYP_CD, '1', false)"></v-select>
            </td>
            <th><label>고객번호</label></th>
            <td><v-text-field class="jh-form is-trns" v-model="contactDetail.CUST_NO" :disabled="true" id="detailTalkStatNm"></v-text-field></td>
            <th><label>고객명</label></th>
            <td><v-text-field class="jh-form is-trns" v-model="contactDetail.CUSTOMER_NM" :disabled="true" id=""></v-text-field></td>
            <!--
            <th><label>상담상태</label></th>
            <td> {{contactDetail.TALK_STAT_NM}} </td>
            -->
            <th rowspan="6"><label>상담내용</label></th>
            <td rowspan="6">
              <v-textarea class="jh-form" height="145px" v-model="contactDetail.TALK_ANS" id="detailTalkAns"></v-textarea>
            </td>
          </tr>
          <tr>
            <th><label>상담시작일자</label></th>
            <td><span style="padding:6px;">{{yyyyMMddHHMISS(contactDetail.TALK_DIST_DT)}}</span></td>
            <td>
              <!--상담유형 중분류-->
              <v-select class="jh-form" v-model="contactDetail.CNSL_TYP_CD_2" :items="mdfyCnslTypCd2" item-text="CD_NM" item-value="CD" placeholder="선택하세요" @change="changeMdfyCouselTyeKey('001', contactDetail.CNSL_TYP_CD_2, '2', false)"></v-select>
            </td>
            <th><label>상담상태</label></th>
            <td><v-text-field class="jh-form is-trns" v-model="contactDetail.TALK_STAT_NM" :disabled="true" id="detailTalkStatNm"></v-text-field></td>
            <th><label>고객ID</label></th>
            <td><v-text-field class="jh-form is-trns" v-model="contactDetail.CUSTOMER_ID" :disabled="true" id=""></v-text-field></td>
            
          </tr>
          <tr>
            <th><label>상담시작시간</label></th>
            <td><span style="padding:6px;">{{yyyyMMddHHMISS(contactDetail.TALK_START_DT)}}</span></td>
            <td>
              <!--상담유형 소분류-->
              <v-select class="jh-form" v-model="contactDetail.CNSL_TYP_CD_3" :items="mdfyCnslTypCd3" item-text="CD_NM" item-value="CD" placeholder="선택하세요"></v-select>
            </td>
            <th><label>상담결과</label></th>
            <td><v-text-field class="jh-form is-trns" v-model="contactDetail.EXEC_RST_NM" :disabled="true" id="detailExecRstNm"></v-text-field></td>
            <th><label>입력번호</label></th>
            <td><span style="padding:6px;">{{ phonNumber(contactDetail.USER_PH_NUM) }}</span></td>
          </tr>
          <tr>
            <th><label>상담종료시간</label></th>
            <td><span style="padding:6px;">{{yyyyMMddHHMISS(contactDetail.TALK_POST_DT)}}</span></td>
            <th rowspan="3"><label>문의유형</label></th>
            <td>
              <!--문의유형 대분류-->
              <v-text-field class="jh-form is-trns" v-model="contactDetail.TALK_INQRY_NM_1" :disabled="true" id=""></v-text-field> 
            </td>
            <th><label>최초상담사</label></th>
            <td><v-text-field class="jh-form is-trns" v-model="contactDetail.USER_ID_NM" :disabled="true" id="detailUserIdNm"></v-text-field></td>
            <th rowspan="3"><label>고객메모</label></th>
            <td rowspan="3" class="is-p-0"><v-textarea class="jh-form is-trns" height="89px" :disabled="true" :value="contactDetail.CUSTOMER_MEMO" id=""></v-textarea></td>
          </tr>
          <tr>
            <th><label>상담소요시간</label></th>
            <td><span style="padding:6px;">{{yyyyMMddHHMISS(contactDetail.CHAT_TIME)}}</span></td>
            <td>
              <!--문의유형 중분류-->
              <v-text-field class="jh-form is-trns" v-model="contactDetail.TALK_INQRY_NM_2" :disabled="true" id=""></v-text-field> 
            </td>
            <th><label>최종상담사</label></th>
            <td><v-text-field class="jh-form is-trns" v-model="contactDetail.TRANS_USER_ID_NM" :disabled="true" id="detailTramsUserIdNm"></v-text-field></td>
          </tr>
          <tr>
            <th><label>고객대기시간</label></th>
            <td><span style="padding:6px;">{{yyyyMMddHHMISS(contactDetail.READY_TIME)}}</span></td>
            <td>
              <!--문의유형 소분류-->
              <v-text-field class="jh-form is-trns" v-model="contactDetail.TALK_INQRY_NM_3" :disabled="true" id=""></v-text-field> 
            </td>
            <th><label></label></th>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 상담사조회 -->
    <v-dialog max-width="1000" v-model="DialogP101801">
      <dialog-P101801
      headerTitle="상담사조회"
      @hide="hideDialog('P101801')"
      :userInfo="userInfo"
      @input="userInfo = $event"
      @selectUser="[hideDialog('P101801'), setUserData()]"
      ></dialog-P101801>
      <template v-slot:body></template>
    </v-dialog>
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import axios from "axios";
import api from "@/store/apiUtil.js";
import apiChat from "../../store/apiChatParamUtil.js";
import { mapGetters } from "vuex";
import dataTables from "@/components/DataTables";
import DialogP101801 from "./P101801.vue";

export default {
  name: "MENU_E020401", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogP101801,
    dataTables
  },
  data() {
    return {
      DialogP101801: false,
      menuStartDate: false,
      menuEndDate: false,
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      CURR_DT : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      talkQst: "",
      cnslrsltcd: "",
      srhCnslRsltCd: [
        { CD_NM: '전체', CD: '' },
      ],
      srhCustomerNm: "",
      srhTalkUserKey: "",
      srhCusLevel: "전체",
      srhUserPhNum: "",
      srhCustId: "",
      talkContactId: "",
      srhDttm: "",
      cnsltypcd: "",
      cnsltypcd2: "",
      cnsltypcd3: "",
      cnsltypcd4: "",
      talkStatCd: "",
      selected_channel: "001",
      userInfo: {},
      asp_new_cust_Key: this.$store.getters["userStore/GE_USER_ROLE"].company,
      srhCallTypCd: [
        { CD_NM: "전체", CD:"" },
        { CD_NM: "PC 홈페이지", CD:"PH" },
        { CD_NM: "모바일 홈페이지", CD:"MH" },
        { CD_NM: "HTS", CD:"HTS" },
        { CD_NM: "MTS-ON", CD:"MTS" },
        { CD_NM: "ARS", CD:"ARS" },
        { CD_NM: "카카오톡", CD:"KAKAO" }
      ],
      selectInCode:"",
      common_code: [],
      srhInqryTypCd: [], //문의유형1
      srhInqryTypCd2: [],
      srhAspSenderKey: [{ value: "001", title: "EBEST" }],
      srhCnslTypCd: [], //상담유형
      srhCnslTypCd2: [], //상담유형2
      srhCnslTypCd3: [], //상담유형3
      srhCnslTypCd4: [], //상담유형4
      selectedRow: [], //선택된 로우
      contactDetail: {
        CNSL_TYP_NM: "",
        CNSL_TYP_NM_2: "",
        CNSL_TYP_NM_3: "",
      }, //상세이력
      
      mdfyCnslTypCd: [], //수정상담유형
      mdfyCnslTypCd2: [], //수정상담유형2
      mdfyCnslTypCd3: [], //수정상담유형3

      CLIENTRATING: [
        { CD_NM: "전체", CD:"" },
        { CD_NM: "최우수", CD:"01" },
        { CD_NM: "우수", CD:"02" },
        { CD_NM: "준우수", CD:"03" },
        { CD_NM: "준우수(소액미달)", CD:"04" },
        { CD_NM: "일반(BEP이상)", CD:"05" },
        { CD_NM: "일반(BEP미만)", CD:"06" },
        { CD_NM: "신규", CD:"07" }
      ], //고객등급

      DEPT_COMBO: [], // 부서콤보
      TEAM_COMBO: [], // 팀콤보
      CNSL_COMBO: [], // 상담원콤보

      ATTR_A: "",
      ATTR_B: "",
      CNSL_ID: "",

      userId: "",
      userNm: "",
      userNick: "",
      /** 문의유형 3depth 변수 */
      inqry_code_1: [],
      select_inqry_code_1: "",
      inqry_code_2: [],
      select_inqry_code_2: "",
      inqry_code_3: [],
      select_inqry_code_3: "",

      gridDataHeaders: [
        { text: "NO", value: "ROW_NUM", align: "center", width: "30px", sortable: false, },
        { text: "상담일시", value: "TALK_DIST_DT", align: "center", width: "80px", },
        { text: "시작시간", value: "TALK_START_DT", align: "center",  width: "80px", },
        { text: "종료시간", value: "TALK_POST_DT", align: "center", width: "80px", },
        { text: "소요시간",  value: "CHAT_TIME", align: "center", width: "80px", },
        { text: "고객명", value: "CUSTOMER_NM", align: "center", width: "80px", },
        { text: "고객번호", value: "CUST_NO", align: "center", width: "80px", },
        { text: "계좌번호", value: "INT_ACC_NO", align: "center", width: "100px", },                                //체크
        { text: "입력번호", value: "USER_PH_NUM", align: "center", width: "100px", },                                  //체크
        { text: "상담유형(대)", value: "CNSL_TYP_NM", align: "center", width: "100px", },
        { text: "상담유형(중)", value: "CNSL_TYP_NM_2", align: "center", width: "100px", },
        { text: "상담유형(소)", value: "CNSL_TYP_NM_3", align: "center", width: "100px", },
        { text: "상담사명/사번", value: "TRANS_USER_ID_NM", align: "center", width: "100px", },
        { text: "고객등급", value: "CUS_LEVEL", align: "center", width: "80px", },                                //체크
        //{ text: "ISSUE", value: "ISSUE_CD", align: "center", width: "80px", },                                        //체크
        { text: "문의유형(대)", value: "TALK_INQRY_NM_1", align: "center",  width: "100px", },
        { text: "문의유형(중)", value: "TALK_INQRY_NM_2", align: "center", width: "100px", },
        { text: "문의유형(소)", value: "TALK_INQRY_NM_3", align: "center", width: "100px", },
        { text: "챗봇",  value: "BOT_INQRY_CD", align: "center", width: "80px", },
        //{ text: "인입채널",  value: "IN_CODE", align: "center", width: "100px", },                                     //체크
        { text: "인입채널",  value: "IN_CODE_NM", align: "center", width: "100px", },                                     //체크
        { text: "개인정보동의여부",  value: "PERS_INF_AGG_YN", align: "center", width: "60px", sortable: false,},                //체크
        { text: "개인정보동의시간",  value: "PERS_INF_AGG_DTTM", align: "center", width: "60px", sortable: false,},              //체크
        { text: "고객ID", value: "TALK_USER_KEY", align: "center", width: "80px", cellClass: "ellipse", },
        { text: "상담상태", value: "TALK_STAT_NM", align: "center", width: "80px", },
        { text: "최초상담사", value: "USER_ID_NM", align: "center", width: "80px", cellClass: "ellipse", },
        { text: "최종상담사", value: "TRANS_USER_ID_NM", align: "center", width: "80px", cellClass: "ellipse", },
        { text: "내용", value: "CONTENT_VIEW",  width: "60px", align: "center", sortable: false,},//체크
        // { text: "상담유형 4단계", value: "CNSL_TYP_NM_4", align: "center", width: "100px", cellClass: "ellipse", },
        // { text: "메신저명", value: "ASP_SENDER_KEY_NM", align: "center", width: "100px", },
        // { text: "회원/비회원", value: "CUSTOMER_MEMBER", align: "center", width: "100px", },
        // { text: "상담제목", value: "TALK_QST", width: "150px", cellClass: "ellipse", },
        // { text: "상담완료일시", value: "TALK_END_DT", align: "center", width: "140px", },
        // { text: "내용", value: "CONTENT_VIEW",  width: "150px", cellClass: "ellipse", },
        // { text: "상담ID", value: "TALK_CONTACT_ID", align: " d-none", width: "100px", },
        // { text: "카카오고객키", value: "TALK_USER_KEY", align: " d-none",  width: "100px", },
        // { text: "CALLID", value: "CALL_ID", align: " d-none", width: "100px", },
        // { text: "이메일", value: "EMAIL", align: " d-none", width: "100px", },
        // { text: "상담ID", value: "TALK_CONTACT_ID", align: " d-none", width: "100px", },
        // { text: "상담결과", value: "EXEC_RST_NM", align: " d-none", width: "100px", },
        // { text: "문의서비스",  value: "CHANNEL_NM", align: " d-none", width: "100px", },
        // { text: "메모", value: "CUSTOMER_MEMO", align: " d-none",  width: "100px", },
        // { text: "플러스친구KEY", value: "ASP_SENDER_KEY", align: " d-none", width: "100px", },
        // { text: "봇서비스명", value: "BOT_SERVICE_NM", align: " d-none", width: "100px", },
        // { text: "UID", value: "TID", align: " d-none", width: "100px", },
        // { text: "고객대기시간", value: "READY_TIME", align: " d-none", width: "100px", },
        // { text: "후처리시간", value: "AFTER_TIME", align: " d-none",  width: "100px", },
        // { text: "총소요시간", value: "TOT_TIME", align: " d-none", width: "100px", },
      ],
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      totalVisible: 10,
      pagination: { page: 1, rowsPerPage: 200, sortBy: "", descending: "" },
      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",
      height: "50px",
      dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '290px',
        itemKey: 'ROW_NUM',
        itemsPerPage: 50,
        items:[],
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        singleSelect: true,
      },
      paginationOptions: {
          totalVisible: 10
      },
    };
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      aspCustList: "userStore/GE_USER_COMPANY"
      ,geUserRole: 'userStore/GE_USER_ROLE'
      ,geUserAttrA: 'userStore/GE_USER_ATTR_A'
    }),
  },  
  async created() { 
    this.dataTableOptions.headers = this.gridDataHeaders;
    this.dataTableOptions.items = this.gridDataText;

    //공통코드설정
    let codeName = ["TALK031", "TALK034"];
    this.common_code = await this.mixin_common_code_get_all(codeName);

    
    //상담유형
    this.srhCnslTypCd.push({ CD_NM: "전체", CD: "" });
    this.srhCnslTypCd2.push({ CD_NM: "전체", CD: "" });
    this.srhCnslTypCd3.push({ CD_NM: "전체", CD: "" });
    this.srhCnslTypCd4.push({ CD_NM: "전체", CD: "" });

    this.mdfyCnslTypCd.push({ CD_NM: "전체", CD: "" });
    this.mdfyCnslTypCd2.push({ CD_NM: "전체", CD: "" });
    this.mdfyCnslTypCd3.push({ CD_NM: "전체", CD: "" });

    this.DEPT_COMBO = [{CD: '', CD_NM: '전체'}];
    this.TEAM_COMBO = [{CD: '', CD_NM: '전체'}];
    this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];

    this.ATTR_A = '';
    this.ATTR_B = '';
    this.CNSL_ID = '';

    this.getAttrDiv();
    //this.getAttrDiv('B');
    this.getExecRst();
  },
  async mounted() {
    //문의유형
    this.initInqry();
    //상담유형
    this.initCounselType();
    //상담사
    let get_user_role = this.$store.getters["userStore/GE_USER_ROLE"];
    let chkGroup = this.$store.getters["authStore/GE_AUTH_LOGIN_DATA"].AUTHOR_GROUP_NM_LIST;

    if(chkGroup.indexOf("관리") == -1){
      this.userInfo.USER_ATTR_A = get_user_role.USER_ATTR_A;
      this.userInfo.USER_ATTR_B = get_user_role.USER_ATTR_B;
      this.userInfo.USER_ID = get_user_role.userId;
      this.setUserData();
    }
  },

  methods: {
    async initCounselType() {
      this.srhCnslTypCd = await this.mixin_counsel_type_code_get(
        this.selected_channel,
        "*",
        "전체"
      );
      
      //수정 깊은복사
      this.mdfyCnslTypCd = JSON.parse(JSON.stringify(this.srhCnslTypCd))
    },
    
    clear0() {
      //console.log("run clear0");
      this.userId = "";
      this.userNm = "";
      this.userNick = "";
    },
    btnUser() {
      var user = {
        ASP_CUST_KEY: this.selected_channel,
        USER_ID: this.userId,
        USER_NM: this.userNm,
        USER_NICK: this.userNick,
      };
      this.userInfo = user;
    },
    async getExecRst(){
      let codeName4 = ["TALK005"];
      let common_code4 = await this.mixin_common_code_get_all(codeName4, "Y");
      this.srhCnslRsltCd = [ { CD_NM:'전체', CD: ''}, ...common_code4[0].group_value];
    },
    async changeDept(attrA){
      if(this.mixin_isEmpty(attrA)) {
        this.TEAM_COMBO = [{CD: '', CD_NM: '전체'}];
        this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];
      } else {
        this.TEAM_COMBO = await this.mixin_get_attr_by_attr('', attrA);
      }
      this.CNSL_ID = '';
      this.ATTR_B = '';
    },
    async changeUser(attrA, attrB){
      if(this.mixin_isEmpty(attrB)) {
        this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];
      } else {
        this.CNSL_COMBO = await this.mixin_get_user_by_attr(attrA, attrB);
      }
      this.CNSL_ID = '';
    },
    //사용자 소속 콤보박스 생성
    async getAttrDiv() {
      this.DEPT_COMBO = await this.mixin_get_attr_by_attr('****', '');
      this.TEAM_COMBO = [{CD: '', CD_NM: '전체'}];
      this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];

      this.ATTR_A = '';
      this.ATTR_B = '';
      this.CNSL_ID = '';
    },
    setUser() {
      this.userId = this.userInfo.USER_ID;
      this.userNm = this.userInfo.USER_NM;
      this.userNick = this.userInfo.USER_NICK;
      // this.userId1 = this.userInfo.USER_ID;
      // this.userNm1 = this.userInfo.USER_NM;
      // this.userNick1 = this.userInfo.USER_NICK;
    },
    showDialog(type) {
      //팝업 파라미터 설정
      this.popupParams;
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      this[`dialog${type}`] = false;
    },
    //상세내역
    onClickRow(item, row) {
      if (this.selectedRow != "") {
        this.activeRow(this.selectedRow, false);
        this.selectedRow = [];
      }
      this.activeRow(row, true);

      this.contactDetail = JSON.parse(JSON.stringify(item));
      if(this.contactDetail.CNSL_TYP_CD != ""){
        this.changeMdfyCouselTyeKey('001', this.contactDetail.CNSL_TYP_CD, '1', true)
      }
    },
    activeRow(row, value) {
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
      //console.log(`activeRow row : ${JSON.stringify(row)} , value : ${value}`)
    },
    changeDate(type) {
      if(
        moment(this.dates[0]).isBefore(moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD')) ||
        moment(this.dates[1]).isAfter(moment(new Date()).subtract(0, 'days').format('YYYY-MM-DD'))
      ){
        this.common_alert('검색 기간은 최근 1개월까지 선택 가능합니다.');
        const date = moment().format("YYYY-MM-DD");
        this.dates[0] = date;
        this.dates[1] = date;
        this.from = date;
        this.to = date;
        return;
      }

      if(type == 'start') {
        if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(30, 'days').format('YYYY-MM-DD'))) {
          this.common_alert('검색 기간은 최대 1개월까지 선택 가능합니다.');
          const date = moment().format("YYYY-MM-DD");
          this.dates[0] = date;
          this.dates[1] = date;
          this.from = date;
          this.to = date;
          return;
        } else {
          this.from = this.dates[0];
        }
      } else if (type == 'end') {
        if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(30, 'days').format('YYYY-MM-DD'))) {
          this.common_alert('검색 기간은 최대 1개월까지 선택 가능합니다.');
          const date = moment().format("YYYY-MM-DD");
          this.dates[0] = date;
          this.dates[1] = date;
          this.from = date;
          this.to = date;
          return;
        } else {
          this.to = this.dates[1];
        }
      }
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    //년월로 변환
    yyyyMMdd: function (value) {
      // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
      if (value == "") return "";

      // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
      var js_date = new Date(value);

      // 연도, 월, 일 추출
      var year = js_date.getFullYear();
      var month = js_date.getMonth() + 1;
      var day = js_date.getDate();

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      // 최종 포맷 (ex - '2021-10-08')
      return year + "" + month + "" + day;
    },

    yyyyMMddHHMISS(yyyyMMddHHMISS) {
      //접수시간 포맷
      var newTime = "";
      if (!yyyyMMddHHMISS) return "";

      if (yyyyMMddHHMISS.length == 14) {
        newTime =
          yyyyMMddHHMISS.substring(0, 4) +
          "-" +
          yyyyMMddHHMISS.substring(4, 6) +
          "-" +
          yyyyMMddHHMISS.substring(6, 8) +
          " " +
          yyyyMMddHHMISS.substring(8, 10) +
          ":" +
          yyyyMMddHHMISS.substring(10, 12) +
          ":" +
          yyyyMMddHHMISS.substring(12, 14);
        return newTime;
      } else {
        return yyyyMMddHHMISS;
      }
    },

    HHMISS(yyyyMMddHHMISS) {
      //접수시간 포맷
      var newTime = "";
      if (!yyyyMMddHHMISS) return "";

      if (yyyyMMddHHMISS.length == 14) {
        newTime =
          yyyyMMddHHMISS.substring(8, 10) +
          ":" +
          yyyyMMddHHMISS.substring(10, 12) +
          ":" +
          yyyyMMddHHMISS.substring(12, 14);
        return newTime;
      } else {
        return yyyyMMddHHMISS;
      }
    },

    phonNumber(number) {
      //접수시간 포맷
      var pNum = "";
      if (!number) return "";

      if (number.length == 11) {
        pNum =
          number.substring(0, 3) +
          "-" +
          number.substring(3, 7) +
          "-" +
          number.substring(7, 11);
        return pNum;
      } else {
        return number;
      }
    },

    async initInqry() {
      //문의유형 대분류 조회
      this.inqry_code_1 = await this.mixin_inqry_code_get("001", "*");
      this.inqry_code_1.unshift({ CD_NM: "전체", CD: "" });
      this.inqry_code_2.unshift({ CD_NM: "전체", CD: "" });
    },

    //문의유형 중/소분류 조회
    async changeInqryKey(asp_cust_key, spst_inqry_typ_cd, data_type) {
      //data_type: 1  -> 대분류 변경시 중분류 조회
      //data_type: 2  -> 중분류 변경시 소분류 조회

      if (data_type === "1") {
        //대분류 변경시 중소분류 선택값 초기화
        this.select_inqry_code_2 = "";
        this.select_inqry_code_3 = "";
        this.inqry_code_2 = await this.mixin_inqry_code_get(
          asp_cust_key,
          spst_inqry_typ_cd,
          ""
        );
        this.inqry_code_2.unshift({ CD_NM: "전체", CD: "" });
      } else if (data_type === "2") {
        //중분류 변경시 소분류 선택값 초기화
        this.select_inqry_code_3 = "";
        this.inqry_code_3 = await this.mixin_inqry_code_get(
          asp_cust_key,
          spst_inqry_typ_cd,
          ""
        );
      }
    },

    warningAlert(text) {
      let alertText = "항목을 선택해주세요.";

      if (text != null && text != "") {
        alertText = text;
      }
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: alertText,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    //상세내용 수정
    modify() {
      let userId = this.$store.getters["userStore/GE_USER_ID"];
      let rowUserid = (typeof this.contactDetail.USER_ID_NM == 'string')? this.contactDetail.USER_ID_NM.substring((this.contactDetail.USER_ID_NM.indexOf('(') +1) , this.contactDetail.USER_ID_NM.indexOf(')')) : this.contactDetail.USER_ID_NM;
      if(userId != rowUserid) {
          this.common_alert(`다른 직원이 입력한 상담 정보는 \n수정 할 수 없습니다.`, 'info');
          return false;
      }

      let rowDistDt = this.yyyyMMddHHMISS(this.contactDetail.TALK_DIST_DT);
      rowDistDt = (rowDistDt.length > 11)? rowDistDt.substring(0 , 10) : rowDistDt;
      if(this.CURR_DT != rowDistDt) {
          this.common_alert(`당일 입력한 상담 정보만 \n수정 가능 합니다.`, 'info');
          return false;
      }

      if (Object.keys(this.contactDetail).length == 0 || typeof this.contactDetail.TALK_CONTACT_ID === "undefined" || this.contactDetail.TALK_CONTACT_ID === "" ) {
        this.warningAlert();
        return;
      }

      if(this.contactDetail.CNSL_TYP_CD == '' || this.contactDetail.CNSL_TYP_CD_2 == '' || this.contactDetail.CNSL_TYP_CD_3 == ''){
        this.common_alert(`상담유형을 입력해주세요.`, "error");
        return;
      }

      let head = "";
      let dataa = "";
      this.URLData = "/api/chat/history/manage/modify";
      this.HEADER_SERVICE = "chat.history.manage";
      this.HEADER_METHOD = "modify";
      this.HEADER_TYPE = "BIZ_SERVICE";

      //api 호출 부분
      api
        .post(
          this.URLData,
          {
            TALK_CONTACT_ID: this.contactDetail.TALK_CONTACT_ID,
            ASP_SENDER_KEY_NM: this.contactDetail.ASP_SENDER_KEY_NM,
            EXEC_RST_NM: this.contactDetail.EXEC_RST_NM,
            TALK_INQRY_NM_1: this.contactDetail.TALK_INQRY_NM_1,
            TALK_INQRY_NM_2: this.contactDetail.TALK_INQRY_NM_2,
            CNSL_TYP_CD: this.contactDetail.CNSL_TYP_CD,          //CD를 NM에 저장
            CNSL_TYP_CD_2: this.contactDetail.CNSL_TYP_CD_2,      //CD를 NM에 저장
            CNSL_TYP_CD_3: this.contactDetail.CNSL_TYP_CD_3,      //CD를 NM에 저장
            USER_ID_NM: this.contactDetail.USER_ID_NM,
            TRANS_USER_ID_NM: this.contactDetail.TRANS_USER_ID_NM,
            TALK_STAT_NM: this.contactDetail.TALK_STAT_NM,
            TALK_DIST_DT: this.contactDetail.TALK_DIST_DT,
            TALK_QST: this.contactDetail.TALK_QST,
            TALK_START_DT: this.contactDetail.TALK_START_DT,
            TALK_POST_DT: this.contactDetail.TALK_POST_DT,
            TALK_ANS: this.contactDetail.TALK_ANS,
            READY_TIME: this.contactDetail.READY_TIME,
            CHAT_TIME: this.contactDetail.CHAT_TIME,
            AFTER_TIME: this.contactDetail.AFTER_TIME,
            TOT_TIME: this.contactDetail.TOT_TIME,
            
            ASP_NEWCUST_KEY: "001",
            REG_DEPT_CD: "X",
            PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
            UPD_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          },
          {
            head: {
              SERVICE: this.HEADER_SERVICE,
              METHOD: this.HEADER_METHOD,
              TYPE: this.HEADER_TYPE,
              ROW_CNT: "20",
              PAGES_CNT: "1",
            },
          }
        ) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
        .then((response) => {
          //let jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
          //this.gridDataText = jsonObj;
          //console.log(JSON.stringify(response.data));
          //head = JSON.stringify(response.data.HEADER);
          //dataa = JSON.stringify(response.data.DATA);
          this.contactDetail = {};

          this.common_alert("정상적으로 수정되었습니다.");
          this.search();
        }) //api 호출 성공 이후 수행
        .catch((err) => {
          alert(err);
        }); //api호출 에러 작업 수행

      this.HEADERResult = head;
      this.DATAResult = dataa;
      //결과값 보여주기
    },
    //상담유형ChangeEvent
    async changeCouselTyeKey(asp_cust_key, spst_coundel_typ_cd, data_type) {
      if (data_type === "1") {
        this.srhCnslTypCd2 = [{ CD_NM: "전체", CD: "" }];
        this.srhCnslTypCd3 = [{ CD_NM: "전체", CD: "" }];
        this.srhCnslTypCd4 = [{ CD_NM: "전체", CD: "" }];
        this.cnsltypcd2 = "";
        this.cnsltypcd3 = "";
        this.cnsltypcd4 = "";
        this.srhCnslTypCd2 = await this.mixin_counsel_type_code_get(
          this.selected_channel,
          spst_coundel_typ_cd,
          "전체"
        );
      } else if (data_type === "2") {
        this.srhCnslTypCd3 = [{ CD_NM: "전체", CD: "" }];
        this.srhCnslTypCd4 = [{ CD_NM: "전체", CD: "" }];
        this.cnsltypcd3 = "";
        this.cnsltypcd4 = "";
        this.srhCnslTypCd3 = await this.mixin_counsel_type_code_get(
          this.selected_channel,
          spst_coundel_typ_cd,
          "전체"
        );
      } else if (data_type === "3") {
        this.srhCnslTypCd4 = [{ CD_NM: "전체", CD: "" }];
        this.cnsltypcd4 = "";
        this.srhCnslTypCd4 = await this.mixin_counsel_type_code_get(
          this.selected_channel,
          spst_coundel_typ_cd,
          "전체"
        );
      }
    },

    //상담유형ChangeEvent
    async changeMdfyCouselTyeKey(asp_cust_key, spst_coundel_typ_cd, data_type, toggleBool) {
      if (data_type === "1") {
        this.mdfyCnslTypCd2 = [{ CD_NM: "전체", CD: "" }];
        this.mdfyCnslTypCd3 = [{ CD_NM: "전체", CD: "" }];
        this.mdfyCnslTypCd2 = await this.mixin_counsel_type_code_get(
          this.selected_channel,
          spst_coundel_typ_cd,
          ""
        );
        this.contactDetail.CNSL_TYP_CD_2 = toggleBool ? this.contactDetail.CNSL_TYP_CD_2 : this.mdfyCnslTypCd2[0].CD;
        this.contactDetail.CNSL_TYP_CD_3 = toggleBool ? this.contactDetail.CNSL_TYP_CD_3 : "";
        this.changeMdfyCouselTyeKey('001', this.contactDetail.CNSL_TYP_CD_2, '2', toggleBool);
      } else if (data_type === "2") {
        this.mdfyCnslTypCd3 = [{ CD_NM: "전체", CD: "" }];
        this.mdfyCnslTypCd3 = await this.mixin_counsel_type_code_get(
          this.selected_channel,
          spst_coundel_typ_cd,
          ""
        );
        this.contactDetail.CNSL_TYP_CD_3 = toggleBool ? this.contactDetail.CNSL_TYP_CD_3 : this.mdfyCnslTypCd3[0].CD;
      }
    },

    //조회
    async search() {
      this.URLData = "/api/chat/history/manage/list";
      this.HEADER_SERVICE = "chat.history.manage";
      this.HEADER_METHOD = "list";
      this.HEADER_TYPE = "BIZ_SERVICE";

      let head = "";
      let dataa = "";

      await api
        .post(
          this.URLData,
          {
            ASP_NEWCUST_KEY: this.selected_channel,
            ASP_CUST_KEY: this.selected_channel,
            SEARCH_FROM: this.yyyyMMdd(this.dates[0]),
            SEARCH_TO: this.yyyyMMdd(this.dates[1]),
            SRH_CALL_TYP_CD: "",
            USER_NICK: this.userNick,
            SRH_INQRY_TYP_CD: this.select_inqry_code_1,
            SRH_INQRY_TYP_CD2: this.select_inqry_code_2,
            SRH_INQRY_TYP_CD3: this.select_inqry_code_3,
            SRH_CNSL_RSLT_CD: this.cnslrsltcd,
            TALK_STAT_CD: this.talkStatCd,
            SRH_ASP_SENDER_KEY: "",
            TALK_CONTACT_ID: this.talkContactId,
            SRH_CNSL_TYP_CD: this.cnsltypcd,
            SRH_CNSL_TYP_CD2: this.cnsltypcd2,
            SRH_CNSL_TYP_CD3: this.cnsltypcd3,
            TALK_QST: this.talkQst,
            SRH_DTTM: this.srhDttm,

            //TODO : 추가조건조회 (입력번호 ???)
            IN_CODE: this.selectInCode,
            USER_ID: this.CNSL_ID,
            USER_ATTR_A: this.ATTR_A,
            USER_ATTR_B: this.ATTR_B,
            SRH_CUSTOMER_NM: this.srhCustomerNm,
            SRH_CUST_NO: this.srhCustId,
            SRH_TALK_USER_KEY: this.srhTalkUserKey,
            SRH_CUS_LEVEL: (this.srhCusLevel == '전체')? '' : this.srhCusLevel,
            USER_PH_NUM: this.srhUserPhNum,

          }, //api에서 사용될 data 입력
          {
            head: {
              SERVICE: this.HEADER_SERVICE,
              METHOD: this.HEADER_METHOD,
              TYPE: this.HEADER_TYPE,
              ROW_CNT: this.pagination.rowsPerPage,
              PAGES_CNT: this.pagination.page,
            },
          }
        )
        .then((response) => {
          let jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
          let that = this;
          if(Array.isArray(jsonObj) ){
            jsonObj.forEach(obj => {
              let fData = that.srhCallTypCd.filter(fObj => fObj.CD == obj.IN_CODE);
              obj.IN_CODE_NM = (Array.isArray(fData) && fData.length > 0)? fData[0].CD_NM : obj.IN_CODE;
            })
          }
          this.dataTableOptions.items = jsonObj;

          //console.log(`search SUCCESS : ${JSON.stringify(response.data)}`);
          head = JSON.stringify(response.data.HEADER);
          dataa = JSON.stringify(response.data.DATA);
        }) //api 호출 성공 이후 수행
        .catch((err) => {
          alert(err);
        }); //api호출 에러 작업 수행

      this.HEADERResult = head;
      this.DATAResult = dataa;
      //결과값 보여주기
    },
    isActiveRow(item) {
      const activeClass =
        item.ROW_NUMBER === this.contactDetail.ROW_NUMBER ? "is-active" : "";
      return activeClass;
    },
    
    openP101102(item){


            let agentId = this.userInfo.USER_ID;
            let startDt = item.TALK_DIST_DT.replaceAll("-","");

            if(this.mixin_isEmpty(startDt)) {
                this.common_alert(`녹취기간 정보가 없습니다.`, 'info');
                return false;
            }

            // if(this.mixin_isEmpty(callKey)) {
            //     this.common_alert(`녹취번호가 없습니다.`, 'info');
            //     return false;
            // }

            // 당일건만 청취20230511130910
            // console.log("startDt.substr(0, 8) ", startDt.substr(0, 8))
            if(startDt.substr(0, 8) != moment().format("YYYYMMDD") && this.geUserRole.atrtGroupId == '20220826113539330TWBaSY4N') {
                this.common_alert(`당일건만 조회 가능합니다.`, 'info');
                return false;
            }

            // 상담사일경우
            // if(this.geUserRole.atrtGroupId == '20220826113539330TWBaSY4N') {
            //     if(item.USER_ID != agentId) {
            //         this.common_alert(`본인의 상담건만 조회 가능합니다.`, 'info');
            //         return false;
            //     }
            // } else {
                // 관리자는 소속부서만 청취가능
                if(item.USER_ATTR_A != this.geUserAttrA) {
                    this.common_alert(`본인의 소속부서건만 조회 가능합니다.`, 'info');
                    return false;
                }
            // }
            
            

      //console.log(`openP101102 call item : ${JSON.stringify(item)}`);
      //popup token 처리
      this.getTalkHistList(item.TALK_CONTACT_ID);
      this.$openWindow('P101102','P101102','500','800');
    },

    //상담이력 조회
    getTalkHistList(key){
      apiChat.post("api/chat/main/cnslt-hist/chat-inqire/"+key,   //api url입력
      {
        ASP_NEWCUST_KEY: this.selected_channel,
        ASP_CUST_KEY: this.selected_channel,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        action:'api/chat/main/cnslt-hist/chat-inqire/'+key,
        method:'post'
      },
      {
        head: {
      }})
      .then((response) => {
        console.log("상담이력<<<<>>>>",response.data.data);
        if(Array.isArray(response.data.data)){
          for (var i = 0; i < response.data.data.length; i++) {
            // response.data.data[i].CONTENT = response.data.data[i].CONTENT.replace(/\\\\/g, "\\").replace(/\\r\\n/g, "\\n").replace(/\\/g, "");
            response.data.data[i].CONTENT = response.data.data[i].CONTENT.replaceAll("\\n", "\n");
            response.data.data[i].CONTENT = response.data.data[i].CONTENT.replaceAll("\\", "");
            response.data.data[i].CONTENT = response.data.data[i].CONTENT.replace(/\\\\/g, "\\").replace(/\\r\\n/g, "\n").replace(/\\/g, "");            
          }
        }
        localStorage['CHAT_DETAIL_LIST'] = JSON.stringify(response.data.data);

        //console.log("ChatList:::");
        //console.log(JSON.parse(localStorage['CHAT_DETAIL_LIST']));
        //this.$forceUpdate();
      })
      .catch((err) => {
        alert(err);
      })
    },

    showDialog(menu) {
    //   if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
    //     this.showAlert("회사를 먼저 선택해주세요.");
    //     return;
    //   }
      this[`Dialog${menu}`] = true;
    },
    hideDialog(menu) {
      this[`Dialog${menu}`] = false;
    },
    
    async setUserData(){
        this.TEAM_COMBO = await this.mixin_get_attr_by_attr('', this.userInfo.USER_ATTR_A);
        this.CNSL_COMBO = await this.mixin_get_user_by_attr(this.userInfo.USER_ATTR_A, this.userInfo.USER_ATTR_B);

        this.ATTR_A = this.userInfo.USER_ATTR_A
        this.ATTR_B = this.userInfo.USER_ATTR_B
        this.CNSL_ID = this.userInfo.USER_ID

    },

    downloadExcel(tableId, fileNm, sheetNm){
      //number,date,time,string - n,d,t,s
        var exceltypeArr = {};
        exceltypeArr[0]          = "n";
        exceltypeArr[1]       = "s";
        exceltypeArr[2]       = "t";
        exceltypeArr[3]       = "t";
        exceltypeArr[4]       = "t";
        exceltypeArr[5]        = "s";
        exceltypeArr[6]       = "s";
        exceltypeArr[7]       = "s";
        exceltypeArr[8]       = "s";
        exceltypeArr[9]    = "s";
        exceltypeArr[10]    = "s";
        exceltypeArr[11]    = "s";
        exceltypeArr[12]    = "s";
        exceltypeArr[13]       = "s";
        exceltypeArr[14]    = "s";
        exceltypeArr[15]    = "s";
        exceltypeArr[16]    = "s";
        exceltypeArr[17]          = "s";
        exceltypeArr[18]       = "s";
        exceltypeArr[19]  = "s";
        exceltypeArr[20]  = "t";
        exceltypeArr[21]        = "s";
        exceltypeArr[22]       = "s";
        exceltypeArr[23]      = "s";
        exceltypeArr[24]      = "s";
        exceltypeArr[25]          = "s";
        
        //엑셀다운로드
        this.table_id   = tableId;
        this.file_name  = fileNm;
        this.sheet_name = sheetNm;
        this.mixin_common_exportExcel(exceltypeArr);
    },
  },
};
</script>

<style></style>
